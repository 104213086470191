import { MobileService, MobileTariffSliceItem } from '@/store/slice/mobile-tariff.slice';
import {
  HOME_INTERNET_SERVICE_TYPE,
  ServiceHomeInternet,
} from '@/store/api/service-home-internet/service-home-internet.type';
import { ServiceItem } from '@/store/slice/home-internet.slice';
import { getFileLink } from '@/shared/lib/get-document-link';
import { TariffCardOptionProps } from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option.types';
import { ServiceCardProps } from '@/modules/tariff/home-internet-tariffs/services/service-card';
import { MobileTariffMappedOptions } from '.';

export const homeInternetServiceToMobileService = ({
  item,
  added,
}: {
  item: ServiceCardProps;
  added: boolean;
}): MobileService => ({
  servicePrice: item.prices.convergent_price || 0,
  title: item.localized_name,
  serv_id: item?.service?.data?.attributes?.serv_id || item.shpd_id,
  shpd_serv_id: item.shpd_id,
  description: item.description,
  added,
});

export const serviceItemToTariffCardOptionProps = (arr: ServiceItem[]): TariffCardOptionProps[] =>
  arr.map(({ service_type, localized_name }) => ({
    icon: service_type === HOME_INTERNET_SERVICE_TYPE.SPEED ? 'speed' : 'tv',
    value: localized_name?.split(' ')[0],
    text: localized_name?.split(' ')[1],
  }));

export const homeInternetSliceMapper = (
  {
    name,
    localized_name,
    description,
    shpd_id,
    promotion_description,
    promotion_period,
    prices,
    building_type,
    service_type,
    default_tariff_home_internets,
    affects_promotion,
    order_with_mobile_tariff,
    old_shpd_id,
    service
  }: Omit<ServiceHomeInternet, 'tariffs' | 'background_image' | 'priority'>,
  slider_value: number,
): ServiceItem => ({
  name,
  localized_name,
  description,
  shpd_id,
  promotion_description,
  promotion_period,
  prices,
  building_type,
  service_type,
  slider_value,
  default_tariff_home_internets,
  affects_promotion,
  order_with_mobile_tariff,
  old_shpd_id,
  service
});

export const mobileSliceMapper = ({
  ...props
}: MobileTariffMappedOptions): MobileTariffSliceItem => ({
  localized_name: props?.localized_name,
  name: props?.name,
  text: props?.text,
  shpd_prices: props?.shpd_prices,
  shpd_id_apartment: props?.shpd_id_apartment,
  shpd_id_house: props?.shpd_id_house,
  optionsMobile: props?.optionsMobile,
  price: props?.price,
  promotion_price: props?.promotion_price,
  offer_file: getFileLink(props?.offer_file),
  shpd_pdf: getFileLink(props?.shpd_pdf),
  affects_shpd_promotion: props?.affects_shpd_promotion?.data?.map(({ attributes }) => attributes.shpd_id),
  features: props?.features,
});
