import React, { FC } from 'react';
import Link from 'next/link';
import { Image, Button } from '@mantine/core';
import cn from 'classnames';
import { Path } from '@/shared/constants/links';
import { formatPrice } from '@/shared/lib/utils';
import CheckboxRecHI from '@/shared/assets/svg/checkbox-rec-hi.svg';
import { HOME_INTERNET_TARIFFS__CONSTANTS } from '@/modules/tariff/home-internet-tariffs/constants';
import { MOYA_VOLNA } from '@/modules/tariff/shared/constants';
import { TABS } from '@/widgets/tariffs-carousel/constants';
import { TariffCardProps } from './tariff-card.types';
import TariffCardOption from './tariff-card-option/tariff-card-option';
import { HomeInternetCardContent } from './home-internet-card-content';
import { reachGoal } from '@/shared/lib/ym';
import { Features } from './features';
import { Checkbox } from '..';
import { YM } from '@/shared/constants/yandex-metrika';
import styles from './tariff-card.module.scss';

const TariffCard: FC<React.PropsWithChildren<TariffCardProps>> = ({
  background_image,
  name,
  localized_name,
  price = 0,
  promotion,
  promotionPriceFrom,
  priceFrom,
  withoutPrice,
  optionsMobile = [],
  optionsHomeInternet = [],
  offer_file,
  activeTab,
  homeInternetPage,
  added,
  onClick,
  className,
  titleClassName,
  features,
  isLandingPage,
  additionalOptions,
}) => {
  const isMoyaVolna = name === MOYA_VOLNA.NAME;

  const actualPrice = promotion?.promotion_price ?
    {
      price: promotion.promotion_price,
      from: promotionPriceFrom,
    } :
    priceFrom ?
      {
        price: priceFrom,
        from: true,
      } :
      {
        price,
        from: false,
      };
  const oldPrice = priceFrom ?
    {
      price: priceFrom,
      from: true,
    } :
    {
      price,
      from: false,
    };

  const handleMobileTariffClick = () => {
    reachGoal(YM.click_cart_mobile_tarif, { name: name ?? '' });
  };

  const handleHomeInternetTariffClick = () => {
    reachGoal(YM.click_cart_home_internet, { name: name ?? '' });
  };

  const handleButtonClick = () => {
    if (activeTab === TABS.MOBILE) {
      handleMobileTariffClick();
    } else if (activeTab === TABS.INTERNET) {
      handleHomeInternetTariffClick();
    }
  };

  const buttonToHomeInternetPage = (
    <>
      {activeTab === TABS.MOBILE ? (
        <Checkbox className={styles.checkbox} added={added} onClick={onClick} />
      ) : (
        <Button
          classNames={{
            root: cn(styles.button__root, { [styles.button__added]: added }),
          }}
          onClick={onClick}
        >
          {added ? (
            <div className={styles.button__withIcon}>
              <CheckboxRecHI />
              <span>{HOME_INTERNET_TARIFFS__CONSTANTS.ADDED_BUTTON}</span>
            </div>
          ) : isLandingPage ? (
            HOME_INTERNET_TARIFFS__CONSTANTS.ORDER
          ) : (
            HOME_INTERNET_TARIFFS__CONSTANTS.ADD_BUTTON
          )}
        </Button>
      )}

      {offer_file ? (
        <Link className={styles.link} target='_blank' href={offer_file}>
          {activeTab === TABS.MOBILE
            ? HOME_INTERNET_TARIFFS__CONSTANTS.ABOUT_MOBILE_TARIFF_BUTTON
            : HOME_INTERNET_TARIFFS__CONSTANTS.ABOUT_TARIFF_BUTTON}
        </Link>
      ) : null}
    </>
  );
  const isInternetTab = activeTab === TABS.INTERNET;

  return (
    <div
      className={cn(styles.root, styles.root__mainPage, isInternetTab && styles.root__homeTab, className && className)}
    >
      {background_image?.data && (
        <Image
          height='100%'
          width='310px'
          src={background_image?.data?.attributes?.url}
          alt={background_image?.data?.attributes?.alternativeText || ''}
          classNames={{
            root: styles.image__root,
            figure: styles.image__figure,
            imageWrapper: styles.image__wrapper,
            image: styles.image,
          }}
        />
      )}
      <div className={styles.content}>
        <div className={styles.information}>
          <span className={cn(styles.title, titleClassName && titleClassName)}>{localized_name}</span>
          {activeTab === TABS.MOBILE ? (
            optionsMobile?.map((option, index) => <TariffCardOption key={index} {...option} />)
          ) : (
            <HomeInternetCardContent optionsHomeInternet={optionsHomeInternet} mobileOptions={optionsMobile} />
          )}
          {features && features.length > 0 ? <Features data={features} /> : null}
          {additionalOptions}
        </div>
        <div className={styles.bottom}>
          {!withoutPrice && (
            <div className={styles.price__container}>
              <div className={styles.price__content}>
                {oldPrice.price !== actualPrice.price && (
                  <div className={styles.price__old}>
                    {oldPrice.from && <span>от</span>}
                    <span>{oldPrice.price} ₽</span>
                  </div>
                )}
                <div className={styles.price__actual}>
                  {actualPrice.from && (
                    <span className={cn({ [styles.price__from]: oldPrice.price === actualPrice.price })}>от</span>
                  )}
                  <span className={styles.price__number}>{actualPrice.price}₽</span>
                  <span>/мес.</span>
                </div>
              </div>
              {promotion?.promotion_period && (
                <div className={cn(styles.price__promotion, { [styles.price__promotion__landing]: isLandingPage })}>
                  {/* {promotion.promotion_period} {formatPrice(promotion.promotion_price)} */}
                  {promotion.promotion_period}
                </div>
              )}
              {isMoyaVolna && <span className={styles.price__promotion}>{MOYA_VOLNA.INFO}</span>}
            </div>
          )}
          {homeInternetPage ? (
            buttonToHomeInternetPage
          ) : (
            <div className={styles.buttons}>
              <Link
                href={`${Path[activeTab === TABS.MOBILE ? 'tariffMobile' : 'tariffHomeInternet'].root}/${name}`}
                className={styles.buttons__more}
                onTouchStart={handleButtonClick} 
              >
                Подробнее
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TariffCard;
