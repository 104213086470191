import { TariffMobile, TariffMobileEntity } from '@/store/api/tariff-mobile/tariff-mobile.type';
import { TariffHomeInternetCategoryEntity } from '@/store/api/tariff-home-internet-category/tariff-home-internet-catogory.type';
import { TariffHomeInternetEntity } from '@/store/api/tariff-home-internet/tariff-home-internet.type';
import { strapiCollectionResponseMapper } from '@/shared/lib/strapi-mappers';
import { DIY_TARIFF_NAME, EVPATORIA_NAME } from '@/shared/constants/diy';
import { StrapiSingleTypeResponse } from '@/shared/types/strapi.types';
import { 
  HomeInternetTariffCategoryMapped, 
  HomeInternetTariffMappedOptions, 
  MobileTariffMappedOptions,
  tariffHomeInternetOptionsMapper, 
  tariffMobileOptionsMapper, 
  homeInternetPriceCounter 
} from '.';

export const mobileTariffResponseMapper = (resp?: TariffMobileEntity): MobileTariffMappedOptions[] => {
  return strapiCollectionResponseMapper(resp)?.map((tariff) => {
    return {
      ...tariff,
      optionsMobile: tariffMobileOptionsMapper(tariff),
      optionsHomeInternet: tariffHomeInternetOptionsMapper({
        tariff: tariff?.tariff_home_internets?.data?.find(({ attributes }) => attributes.name === DIY_TARIFF_NAME)
          ?.attributes,
      }),
    };
  });
};

export const mobileTariffSingleResponseMapper = (
  resp: StrapiSingleTypeResponse<TariffMobile>,
): MobileTariffMappedOptions => {
  const { id, attributes } = resp?.data || {};

  return {
    id,
    ...attributes,
    optionsMobile: tariffMobileOptionsMapper(attributes),
    optionsHomeInternet: tariffHomeInternetOptionsMapper({
      tariff: attributes?.tariff_home_internets?.data?.find(({ attributes }) => attributes.name === DIY_TARIFF_NAME)
        ?.attributes,
    }),
  };
};

export const homeInternetCategoryResponseMapper = (
  resp: TariffHomeInternetCategoryEntity,
): HomeInternetTariffCategoryMapped[] => {
  return resp?.data?.map(({ attributes, id }) => {
    return {
      ...attributes,
      category_name: attributes?.name,
      category_localized_name: attributes?.localized_name,
      tariff_home_internets: attributes?.tariff_home_internets,
      id: id,
      ...attributes?.default_tariff?.data?.attributes,
      optionsHomeInternet: tariffHomeInternetOptionsMapper({
        tariff: attributes?.default_tariff?.data?.attributes,
      }),
      optionsMobile: tariffMobileOptionsMapper(
        attributes?.default_tariff?.data?.attributes?.tariff_mobiles?.data?.[0]?.attributes,
      ),
      counted_prices: homeInternetPriceCounter(
        attributes?.default_tariff?.data?.attributes,
        attributes?.name === EVPATORIA_NAME,
      ),
      default_tariff_name: attributes?.default_tariff?.data?.attributes?.name,
    };
  });
};

export const homeInternetTariffResponseMapper = (resp?: TariffHomeInternetEntity): HomeInternetTariffMappedOptions[] => {
  return strapiCollectionResponseMapper(resp)?.map((tariff) => {
    return {
      ...tariff,
      optionsHomeInternet: tariffHomeInternetOptionsMapper({ tariff }),
      optionsMobile: tariffMobileOptionsMapper(tariff.tariff_mobiles?.data?.[0]?.attributes),
      counted_prices: homeInternetPriceCounter(tariff, tariff.name === EVPATORIA_NAME),
    };
  });
};
